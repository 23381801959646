<template>
  <div class="grid lg:grid-cols-2 sm:grid-cols-1 gap-x-5 w-full">
    <vs-card class="mt-6 helpCard" v-for="(item, index) in helpData" :key="index">
      <div slot="header">
        <div class="flex flex-col w-full justify-center items-center bg-grey-background py-10">
          <div>
            <img :src="item.icon" :class="isFlipPay ? '' : 'relloIconColor'" alt="Help" />
          </div>
          <div>
            <span class="text-lg text-black font-normal">{{ item.header }}</span>
          </div>
        </div>
      </div>

      <div class="m-auto py-6 pl-4">
        <p class="pb-5 text-base">{{ item.bodyText }}</p>
        <ul>
          <li v-for="(subItem, subIndex) in item.texts" :key="subIndex" class="w-full flex items-center py-2">
            <vs-icon :icon="subItem.icon" color="#BDBDBD" size="18px"></vs-icon>
            <span class="pl-3 text-base text-black" v-html="subItem.text"></span>
          </li>
        </ul>
      </div>
    </vs-card>
  </div>
</template>

<script>
const integrationIcon = require("@/assets/images/integrationicon.svg");
const generalHelpIcon = require("@/assets/images/generalhelpicon.svg");

export default {
  data() {
    return {
      integrationIcon: integrationIcon,
      generalHelpIcon: generalHelpIcon,
      appName: "",
      helpData: [
        {
          icon: generalHelpIcon,
          header: "General help",
          bodyText: "If you need general help with setting up your account:",
          texts: [
            {
              text: 'Check out our <a href="https://{{domain}}" target="_blank" style="color:#00cfff;">knowledge base</a>',
              icon: "school",
            },
            {
              text: "Chat with us using the chatbot at the bottom right of your browser",
              icon: "question_answer",
            },
            {
              text: 'Email us at <a href="mailto:support@{{domain}}">support@{{domain}}</a>',
              icon: "email",
            },
          ],
        },
        {
          icon: integrationIcon,
          header: "Integration help",
          bodyText: "If you need technical help with integrating external systems:",
          texts: [
            {
              text: 'Check out our <a href="https://{{domain}}" target="_blank" style="color:#00cfff;">knowledge base</a>',
              icon: "school",
            },

            {
              text: 'See our <a href="https://apidocs.{{domain}}" target="_blank" style="color:#00cfff;">API documentation</a>',
              icon: "question_answer",
            },
            {
              text: 'Email us at <a href="mailto:integrations@{{domain}}">integrations@{{domain}}</a>',
              icon: "email",
            },
          ],
        },
      ],
    };
  },

  computed: {
    isFlipPay() {
      return this.appName.toLowerCase().includes("flippay");
    },

    domain() {
      if (this.isFlipPay) {
        return "support.flippay.com.au/knowledge";
      } else {
        return "help.rellopay.co";
      }
    },
  },

  created() {
    this.appName = process.env.VUE_APP_NAME || "flippay";

    for (const data of this.helpData) {
      for (const subItem of data.texts) {
        subItem.text = subItem.text.replace(/{{appName}}/g, this.appName);
        subItem.text = subItem.text.replace(/{{domain}}/g, this.domain);
      }
    }
  },
};
</script>

